import { DeepPartial } from '@mangopay/checkout-sdk-core';
import { Theme as ThemeType } from './theme';
import { Language, Translation } from './languages';
import { PaymentMethodOptionItem } from './payment-method';
import { CheckoutSdkEnvironment } from './checkout-sdk-environment';

export enum AmountOptions {
  Currency = 'currency',
  Value = 'value',
}

export interface Amount {
  [AmountOptions.Currency]: string;
  [AmountOptions.Value]: number;
}

export enum SdkOptions {
  ClientId = 'clientId',
  ProfilingMerchantId = 'profilingMerchantId',
  Environment = 'environment',
  PaymentMethods = 'paymentMethods',
  Amount = 'amount',
  Branding = 'branding',
  Locale = 'locale',
  TenantId = 'tenantId',
  //ApiKey = 'apiKey',
  //UserId = 'userId',
  //WalletId = 'walletId',
  //HandlePaymentFlow = 'handlePaymentFlow',
  //AutoCapture = 'autoCapture',
  //HideBillingAddress = 'hideBillingAddress',
  //AllowSaveCard = 'allowSaveCard',
  //IsSavedCardCvcRequired = 'isSavedCardCvcRequired',
  //SupportedCardBrands = 'supportedCardBrands',
}

export interface CheckoutSdkOptions {
  [SdkOptions.ClientId]: string;
  [SdkOptions.Amount]: Amount;
  [SdkOptions.ProfilingMerchantId]: string;
  [SdkOptions.Environment]?: CheckoutSdkEnvironment;
  [SdkOptions.PaymentMethods]?: PaymentMethodOptionItem[];
  [SdkOptions.Branding]?: DeepPartial<ThemeType>;
  [SdkOptions.Locale]?: Language | Translation;
  [SdkOptions.TenantId]?: 'EU' | 'UK';
  //[SdkOptions.ApiKey]: string;
  //[SdkOptions.UserId]: string;
  //[SdkOptions.WalletId]?: string;
  //[SdkOptions.HandlePaymentFlow]?: boolean;
  //[SdkOptions.AutoCapture]?: boolean;
  //[SdkOptions.HideBillingAddress]?: boolean;
  //[SdkOptions.AllowSaveCard]?: boolean;
  //[SdkOptions.IsSavedCardCvcRequired]?: boolean;
  //[SdkOptions.SupportedCardBrands]?: CardBrand[];
}

export interface CheckoutSdkFrameOptions extends CheckoutSdkOptions {
  handleCardPayment?: boolean;
  handleGooglePayPayment?: boolean;
  handleApplePayPayment?: boolean;
}
