import {
  CheckoutSdkFrameEventMap,
  CheckoutSdkHostEventMap,
  CheckoutSdkHostEventType,
  CheckoutSdkProxyMessagesOf,
  DebuggerLogType,
} from '@mangopay/checkout-sdk-hosted-core';
import { useRef } from 'react';
import { SdkEventsDispatcherHandlers } from './types';
import { sdkEventsDispatcherInstance } from './sdk-events-dispatcher';
import { useGlobalContext } from '../globalContext';
import { validateRequiredQueryParams } from '../utils/get-query-params';
import { isSdkContext } from '../utils/execution-context';
import { useSentryDebugger } from '../sentryLogger';

export const useSdkEventsDispatcher = () => {
  const { activePaymentMethod } = useGlobalContext();
  const { addBreadcrumb, logError } = useSentryDebugger();
  const { sourceOrigin, host } = validateRequiredQueryParams();
  const handlersRef = useRef<SdkEventsDispatcherHandlers>();
  const handleLogHostEvent = (data: { type: string; data: unknown }) => {
    addBreadcrumb(data.type as DebuggerLogType, data.data as object);
  };
  const handleLogHostError = (data: { type: string; data: unknown }) => {
    handleLogHostEvent(data);
    logError(new Error(data.type));
  };
  const sdkIncomingEventsHandler = (message: CheckoutSdkProxyMessagesOf<CheckoutSdkHostEventMap>) => {
    if (!handlersRef.current) {
      return;
    }
    const {
      handleInitialize,
      handleEnable,
      handleDisable,
      handleHostAuthComplete,
      handleHostSetErrorMessage,
      handlePayment,
      handleAuthorizeApplePayComplete,
      handleSetLoading,
    } = handlersRef.current;

    switch (message.eventType) {
      case CheckoutSdkHostEventType.Initialize:
        handleInitialize(message.data);
        break;
      case CheckoutSdkHostEventType.Enable:
        handleEnable();
        break;
      case CheckoutSdkHostEventType.Disable:
        handleDisable();
        break;
      case CheckoutSdkHostEventType.HostAuthComplete:
        handleHostAuthComplete(message.data).catch(() => {});
        break;
      case CheckoutSdkHostEventType.SetErrorMessage:
        handleHostSetErrorMessage(message?.data);
        break;
      case CheckoutSdkHostEventType.CompletePayment:
        handlePayment(activePaymentMethod);
        break;
      case CheckoutSdkHostEventType.AuthorizeApplePayComplete:
        handleAuthorizeApplePayComplete(message?.data);
        break;
      case CheckoutSdkHostEventType.LogHostEvent:
        handleLogHostEvent(message.data);
        break;
      case CheckoutSdkHostEventType.LogHostError:
        handleLogHostError(message.data);
        break;
      case CheckoutSdkHostEventType.SetLoading:
        handleSetLoading(message?.data);
        break;
      case CheckoutSdkHostEventType.CreateCardRegistrationComplete:
        // event handles in useCardPayment hook
        break;
      case CheckoutSdkHostEventType.CreatePaypalPaymentComplete:
        // event handles in usePaypal hook
        break;
      case CheckoutSdkHostEventType.CreateCardPaymentComplete:
        // event handles in useCardPayment hook
        break;

      default:
        // eslint-disable-next-line no-console
        console.warn(`Unknown event type: "${message.eventType}".`);
    }
  };

  const initSdkEventsDispatcher = (handlers: SdkEventsDispatcherHandlers) => {
    sdkEventsDispatcherInstance.init(sdkIncomingEventsHandler);
    handlersRef.current = handlers;

    const unsubscribe = sdkEventsDispatcherInstance.listen(host, sourceOrigin);

    return unsubscribe;
  };
  const updateHandlers = (handlers: SdkEventsDispatcherHandlers) => {
    handlersRef.current = handlers;
  };
  const dispatchMessageToApp = <T extends keyof CheckoutSdkFrameEventMap>(
    eventType: T,
    data: CheckoutSdkFrameEventMap[T]
  ) => {
    if (isSdkContext) {
      sdkEventsDispatcherInstance.dispatchToApp(eventType, data);
    }
  };

  return {
    initSdkEventsDispatcher,
    dispatchMessageToApp,
    updateHandlers,
  };
};
